.jumbotron {
  margin-bottom: 0;
}

.jumbotron-projects {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.jumbotron-clear {
  background-color: transparent;
}

.jumbotron-footer {
  background-color: #282929;
}

.navbar-only {
  box-shadow: 0 0 10px -5px gray;
  background-color: rgba(255,255,255,.85) !important;
  backdrop-filter: blur(.25rem) !important;
}

.card {
  border: 0;
  border-radius: 15px;
  box-shadow: 0 0 10px -5px gray;
}
.card-glass {
  background-color: rgba(255, 255, 255, .5);
  backdrop-filter: blur(.25rem);
}
.card-glass-dark {
  background-color: rgba(25, 25, 25, .5);
  backdrop-filter: blur(.25rem);
  color: white;
  box-shadow: 0 0 10px -5px black;
}

.bg-pattern-light {
  background: url("../img/background.svg") white;
  background-size: 700px;
}
.bg-pattern-dark {
  background: url("../img/background-dark.svg") #2A2A2A;
  background-size: 700px;
}

.btn:disabled {
  cursor: not-allowed;
}
