$linkedin-color: #0077b5;
$stackoverflow-color: #f48024;
$github-color: #333;
$gitlab-color: #554488;

@mixin btn($color) {
  color: white;
  background-color: $color;
  border-color: $color;
  &:hover {
    background-color: darken($color, 5%);
    border-color: darken($color, 5%);
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba($color, .5);
  }
  padding: 10px 20px;
}

.btn-linkedin {
  @include btn($linkedin-color);
}
.btn-stackoverflow {
  @include btn($stackoverflow-color);
}
.btn-github {
  @include btn($github-color);
}
.btn-gitlab {
  @include btn($gitlab-color);
}