.jumbotron-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.jumbotron-opacity-65 {
  background-color: rgba(0, 0, 0, 0.65);
}
.jumbotron-opacity {
  @extend .jumbotron-opacity-50;
}

.jumbotron-mobler {
  background-image: url("../img/projects/mobler/mobler-background.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}
.img-mobler-logo {
  max-height: 75px;
}
.img-mobler-logo-detail {
  max-height: 125px;
  margin-bottom: 25px;
}

.jumbotron-paint {
  background-image: url("../img/projects/paint/paint-background.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}
.img-paint-logo {
  max-height: 75px;
}

.jumbotron-reptoads {
  background-image: url("../img/projects/reptoads/reptoads-background.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}
.img-reptoads-logo {
  max-height: 95px;
}

.jumbotron-lumberjacked {
  background-image: url("../img/projects/lumberjacked/lumberjacked-background.png");
  background-position: center;
  background-size: cover;
  color: white;
}
.img-lumberjacked-logo {
  max-height: 100px;
}

.jumbotron-ios {
  background-image: url("../img/projects/ios/ios-apps-background.png");
  background-position: center;
  background-size: cover;
  color: white;
}

.platform {
  width: 150px;
  height: 47.5px;
  img {
    position: absolute;
  }
  .platform-soon {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.75);
    line-height: 47.5px;
    text-align: center;
  }
}

.ios-app-icon {
  border-radius: 22.37px;
}
