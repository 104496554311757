$carousel-height: 600px;
$image-width: 800px;

.home-carousel {
  height: $carousel-height;
  position: relative;
  .home-carousel-content,
  .home-carousel-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .home-carousel-content {
    padding-top: 200px;
  }
  .home-carousel-background {
    background-color: #282929;
    //overflow-x: scroll;
    overflow-x: hidden;
    .home-carousel-background-scroller {
      width: 100vw * 3;
      & > img {
        position: absolute;
        top: 0;
        height: 100%;
        width: $image-width;
        object-fit: cover;

        $imageChildren: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16;
        @each $i in $imageChildren {
          &:nth-child(#{$i}) {
            left: (-$image-width) + ($image-width / 1.335) * $i;
            //left: (-$image-width) + ($image-width / 2) * $i;
            //@if $i % 2 == 0 {
            //  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            //}
            //@if $i % 2 != 0 {
            //  clip-path: polygon(50% 100%, 0 0, 100% 0);
            //}
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
          }
        }
      }
    }
  }
}
.home-carousel-content {
  height: 80px !important;
}