.title {
  font-size: 75px !important;
  color: white !important;
}
@media (max-width: 768px) {
  .title {
    font-size: 50px !important;
  }
}
.title-app {
  margin-top: 20px;
  text-align: left;
}

.jumbotron-rotate {
  transform: skew(0deg, -5deg) translateY(-90px);
}
.jumbotron-rotate > .container {
  transform: skew(0deg, 5deg);
}

.jumbotron-top {
  background: #0b99e6; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, #0b99e6, #00ceff); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #0b99e6, #00ceff); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #0b99e6, #00ceff); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #0b99e6, #00ceff); /* Standard syntax */
}
.jumbotron-orange {
  background: #FF6926; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, #FF6926, #FF9A35); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #FF6926, #FF9A35); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #FF6926, #FF9A35); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #FF6926, #FF9A35); /* Standard syntax */
}
.jumbotron-app-top {
  min-height: 600px;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .jumbotron-app-top {
    height: unset;
    padding-top: 120px;
  }
}
@media (min-width: 1200px) {
  .jumbotron-app-top {
    height: 750px;
    padding-top: 120px;
  }
}

.appstore {
  height:75px
}
